import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
    Button
} from "@mui/material"

import SwedishFlag from '../images/swedish.png'

const StyledArticle = styled.article`
    /* position: relative; */
    /* z-index: -2; */
    background: var(--main-gray);
    border-radius: 5px;
    margin: 1rem 0;
    padding: .2rem .6rem 0 .6rem;
    @media screen and (min-width: 640px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    a {
        position: static;
        button {
            position: static;
        }
    }

    a p {
        margin-top: 1rem;
        color: var(--main-black);
    }
    a p:hover {
        text-decoration: underline;
    }
    
    .time { 
        display: flex;
        justify-content: space-between;
    }
`

const PostPreview = ({ post }) => {
    let nbspTags = post.frontmatter.tags.map(tag => tag = tag.replace(" ", "&nbsp;"))

    return (
        <StyledArticle className="postPreview" key={post.id}>
            <div className="tags flex flex-wrap justify-between pt-2 mb-3">
                <div>{nbspTags.map(tag => <span key={tag} className="tag" dangerouslySetInnerHTML={{ __html: tag }} />)}</div> {post.frontmatter.language === "Swedish" ? <img src={SwedishFlag} className="h-5"></img> : null}
            </div>
            <h3 className="mt-2">{post.frontmatter.title}</h3>
            <hr></hr>
            <p className="italic text-lg mb-8">{post.frontmatter.introduction}</p>
            <p>{post.excerpt}</p>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            <Link to={`${post.fields.slug}`}><Button /*style={{ zIndex: "-1" }}*/ className='italic pl-0 text-base'>CONTINUE READING &nbsp;&nbsp;➤</Button></Link>
            <hr></hr>
            <div className="time">
                <small>{post.frontmatter.date}</small>
                <small>{post.timeToRead} min read</small>
            </div>
        </StyledArticle>
    )
}

export default PostPreview
