import React, { useState, useEffect, createContext, useLayoutEffect } from "react"
import Select from "react-select"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import PostPreview from "../components/PostPreview"
import FlagButton from "../components/FlagButton"
import "../templates/blog.scss"

import swedishFlag from '../images/swedish.png'
import englishFlag from '../images/english.png'

export default function Blog({ data, location }) {
  const { posts } = data.blog
  const { tagFromPost } = location.state || {}
  const [tags, setTags] = useState([])
  const [renderedTags, setRenderedTags] = useState([])
  const TagsContext = createContext()
  const [screenWidth, updateScreenWidth] = useState(typeof window !== `undefined` ? window.screen.width : null)

  const [showEnglish, setShowEnglish] = useState(true)
  const [showSwedish, setShowSwedish] = useState(true)

  useLayoutEffect(() => {
    function updateSize() {
      updateScreenWidth(window.innerWidth);
    }
    if (typeof window !== `undefined`) {
      window.addEventListener('resize', updateSize);
      updateSize();
    }
    return () => window.removeEventListener('resize', updateSize);
  }, [])


  useEffect(() => {
    const arr = []
    posts.map(post => {
      post.frontmatter.tags.map(tag => {
        if (!arr.some(index => index.value === tag)) {
          arr.push({ value: tag, label: tag })
        }
      })
    })
    setTags([...arr])
  }, [posts])

  useEffect(() => {
    if (tagFromPost) {
      return setRenderedTags([{ value: tagFromPost, label: tagFromPost }])
    }
    setRenderedTags([...tags])
  }, [tags])

  return (
    <Layout>
      <div>
        <p><span style={{fontWeight: "bold"}}>NOTE!</span> This blog page has been superceded by my <a href="https://plutoastrology.substack.com">Pluto Astrology substack</a> and remains solely as an archive page.</p>
      </div>
      <div>
        <h1 className="border-b border-gray-900">Blog Archive</h1>
        <TagsContext.Provider value={{ tags }}>
          <Select
            defaultValue={tags}
            isMulti
            components={
              screenWidth < 640 ?
                { ClearIndicator: () => null, DropdownIndicator: () => null, IndicatorSeparator: () => null }
                : null
            }
            name="tags"
            options={tags}
            className="basic-multi-select mt-4 static"
            classNamePrefix="select"
            onChange={tagOptions =>
              setRenderedTags(
                tagOptions ? tagOptions.map(option => option) : []
              )
            }
            value={renderedTags}
          />
        </TagsContext.Provider>
        <div className="flex ml-2">
          <FlagButton source={englishFlag} languageString={"English"} showLanguage={showEnglish} setShowLanguage={setShowEnglish}></FlagButton>
          <FlagButton source={swedishFlag} languageString={"Swedish"} showLanguage={showSwedish} setShowLanguage={setShowSwedish}></FlagButton>
        </div>
        {posts.map(post =>
          post.frontmatter.tags.some(i =>
            renderedTags.find(j => j.value === i)
          ) && (post.frontmatter.language === "Swedish" && showSwedish === true || post.frontmatter.language === "English" && showEnglish === true) ? (
            <PostPreview post={post} key={post.id} />
          ) : null
        )}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query postsQuery {
    blog: allMdx(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { order: [DESC, DESC], fields: [frontmatter___date, slug] }
    ) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM Do, YYYY")
          title
          author
          tags
          language
          introduction
        }
        excerpt
        id
        timeToRead
      }
    }
  }
`
